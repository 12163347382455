$blue: #4c9060;
$egg: #f3eeda;
$white: #ffffff;
$black: #1e1e1e;
$soft-blue: #e4f2e8;
$grey: #d8d8d8;

$sm: 'screen and (min-width: 576px)';
$md: 'screen and (min-width: 768px)';
$lg: 'screen and (min-width: 992px)';
$xl: 'screen and (min-width: 1200px)';
$xxl: 'screen and (min-width: 1400px)';
