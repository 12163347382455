* {
  scroll-margin-top: 80px;
  @media #{$md} {
    scroll-margin-top: 96px;
  }
  @media #{$xl} {
    scroll-margin-top: initial;
  }
}

html,
body {
  overflow-x: hidden;
}

.container {
  @media screen and (min-width: 1400px) {
    max-width: 1140px;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: $blue;
  font-weight: bold;
  padding: 6px 16px 2px;
  border-radius: 8px;
  border: 2px solid $black;
  position: relative;
  box-shadow: 3px 3px 0px $black;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: $white !important;
    color: $blue !important;
    border: 2px solid $blue !important;
    box-shadow: 1px 1px 0px $blue !important;
    transform: translateY(2px);
  }
}

.section-title {
  font-size: 24px;
  margin: 0 0 16px;
  font-weight: bold;
  line-height: 1.5;
  @media #{$md} {
    font-size: 32px;
    margin: 0 0 24px;
    line-height: 1.2;
  }
  &.large {
    @media #{$xl} {
      font-size: 40px;
    }
  }
}

.section-sub-title {
  font-size: 20px;
  text-align: center;
  margin: 0 0 16px;
  line-height: 1;
}

.swiper {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.swiper-pagination {
  bottom: -32px !important;
}

.sticked {
  .header {
    border-bottom: 1px solid rgba($black, 0.1);
  }
}
