@font-face {
  font-family: 'yscweb';
  src: url('../fonts/IBMPlexSansThaiLooped-Regular.woff2') format('woff2'),
    url('../fonts/IBMPlexSansThaiLooped-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'yscweb';
  src: url('../fonts/IBMPlexSansThaiLooped-Bold.woff2') format('woff2'),
    url('../fonts/IBMPlexSansThaiLooped-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
a,
input,
p,
ol,
ul,
button,
.ci-text {
  font-family: 'yscweb';
}
