.header {
  padding: 16px 0;
  background-color: $white;
  margin: 0 0 16px;
  position: relative;
  @media #{$xl} {
    margin: 0 0 24px;
    padding: 24px 0 16px;
  }
}

.site-logo {
  margin: 0;
  img {
    max-width: 100%;
    max-height: 64px;
    height: auto;
    display: block;
  }
}

.mobile-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media #{$sm} {
    display: none !important;
  }
  .menu-toggle {
    appearance: none;
    border: 2px solid $black;
    border-radius: 8px;
    background-color: $grey;
    width: 48px;
    height: 48px;
    padding: 0;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 32px;
      height: 32px;
      display: block;
      margin: 0;
    }
  }
  .button {
    color: $white;
  }
}

.site-navigation-wrapper {
  margin: 8px 0 0;
  display: none;
  position: absolute;
  top: 72px;
  left: 8px;
  width: calc(100% - 16px);
  border-radius: 8px;
  border: 2px solid $black;
  box-shadow: 0px 4px 0px rgba($black, 9);
  background-color: $white;
  padding: 24px;
  @media #{$sm} {
    text-align: right;
    display: block !important;
    padding: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
    width: 100%;
    top: 0;
    position: relative;
    background-color: transparent;
  }
  &.active {
    display: block;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
  }
  li {
    display: block;
    // margin: 0 0 16px;
    @media #{$sm} {
      display: inline-block;
      margin: 0 8px 0 0;
    }
    @media #{$md} {
      margin: 0 24px 0 0;
    }
    @media #{$xl} {
      margin: 0 32px 0 0;
    }
    &:nth-child(3n) {
      margin-bottom: 0;
      @media #{$sm} {
        margin-right: 16px;
      }
      @media #{$lg} {
        margin-right: 24px;
      }
      @media #{$xl} {
        margin-right: 32px;
      }
    }
    &:last-child {
      margin-bottom: 0;
      display: none;
      @media #{$sm} {
        display: inline-block;
        margin-right: 0;
      }
      @media #{$lg} {
        margin-right: 8px;
      }
    }
    a,
    > button {
      color: $black;
      font-size: 20px;
      line-height: 1;
      text-decoration: none;
      padding-top: 12px;
      padding-bottom: 12px;
      display: block;
      width: 100%;
      text-align: left;
      &:not(.button) {
        @media #{$xl} {
          padding-bottom: 4px;
          border-bottom: 2px solid transparent;
          &:hover {
            color: $blue;
            border-bottom-color: $blue;
          }
        }
      }
      @media #{$sm} {
        width: auto;
        text-align: center;
        font-size: 12px;
      }
      @media #{$md} {
        font-size: 16px;
      }
      @media #{$lg} {
        font-size: 20px;
      }
      &.button {
        color: $white;
        height: auto;
        padding-bottom: 10px;
        @media #{$lg} {
          height: 48px;
          padding-top: 12px;
          padding-bottom: 8px;
          display: inline-flex;
        }
        @media #{$xl} {
          padding: 6px 16px 2px;
        }
      }
    }
    > button {
      appearance: none;
      border: 0;
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/**
* INTRO
*/

.intro-block {
  margin: 0 0 40px;
  @media #{$xl} {
    margin: 0 0 80px;
  }
  .intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media #{$sm} {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @media #{$lg} {
      flex-wrap: nowrap;
    }
    .box {
      border-radius: 16px;
      background-color: $grey;
      padding: 24px;
      margin: 0 0 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media #{$sm} {
        margin: 0 0 16px;
      }
      @media #{$md} {
        border-radius: 24px;
        margin: 0 0 8px;
      }
      @media #{$xl} {
        margin: 0 0 16px;
      }
      &.egg {
        background-color: $egg;
      }
      &.blue {
        background-color: $blue;
      }
      &.light {
        background-color: $soft-blue;
      }
      p {
        font-size: 16px;
        margin: 0 0 16px;
        line-height: 1.6;
        @media #{$sm} {
          font-size: 14px;
        }
        @media #{$md} {
          font-size: 18px;
        }
        @media #{$xl} {
          font-size: 18px;
          margin: 0 0 24px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .left,
    .center,
    .right {
      flex: 1 0 0;
      min-width: 0;
      flex-direction: column;
      display: flex;
      align-self: stretch;
    }
    .left {
      margin: 0 0 8px;
      @media #{$sm} {
        flex: 0 0 calc((100% - 16px) / 2);
        margin: 0 16px 0 0;
      }
      @media #{$lg} {
        flex: 0 0 calc((100% - 16px) / 3);
        margin: 0 8px 0 0;
      }
      @media #{$xl} {
        flex: 1 0 0;
        margin: 0 16px 0 0;
      }
      .box {
        h2 {
          margin: 0 0 16px;
          font-size: 28px;
        }
        @media #{$lg} {
          &:last-child {
            margin-bottom: 0;
          }
        }
        @media #{$xl} {
          flex: 0 0 180px;
        }
        &.large {
          @media #{$lg} {
            flex: 0 0 350px;
          }
        }
      }
    }
    .center {
      position: relative;
      margin: 0 0 8px;
      @media #{$sm} {
        flex: 0 0 calc((100% - 16px) / 2);
        margin: 0;
      }
      @media #{$lg} {
        flex: 0 0 calc((100% - 16px) / 3);
        margin: 0 8px 0 0;
      }
      @media #{$xl} {
        flex: 1 0 0;
        margin: 0 16px 0 0;
      }
      h4 {
        font-size: 24px;
        line-height: 1;
        margin: 0 0 16px;
        color: $white;
        @media #{$sm} {
          text-align: center;
          line-height: 1.4;
        }
        @media #{$md} {
          margin: 0 0 8px;
        }
        @media #{$lg} {
          font-size: 20px;
        }
        @media #{$xl} {
          font-size: 24px;
          line-height: 1;
          margin: 0 0 16px;
        }
      }
      h5 {
        font-size: 18px;
        line-height: 1;
        margin: 0;
        font-weight: normal;
        color: $white;
        @media #{$sm} {
          font-size: 20px;
          line-height: 1.6;
          text-align: center;
        }
        @media #{$lg} {
          font-size: 18px;
          line-height: 1;
        }
      }
      .imagery {
        flex: 0 0 200px;
        min-width: 0;
        padding: 0;
        background: transparent url('/img/kanchanaburi-imagery-1.jpg') no-repeat center center /
          cover;
      }
      .box.large {
        flex: 0 0 250px;
        align-items: center;
        justify-content: flex-start;
        @media #{$sm} {
          flex: 0 0 310px;
        }
        @media #{$md} {
          flex: 0 0 250px;
        }
        @media #{$lg} {
          flex: 0 0 300px;
          margin-bottom: 0;
        }
        @media #{$xl} {
          flex: 1 0 0;
          padding-top: 40px;
        }
      }
      .team {
        position: absolute;
        bottom: 7px;
        left: 0px;
        width: 350px;
        height: 153px;
        content: '';
        display: block;
        margin: 0;
        background: transparent url('/img/team-imagery.png') no-repeat center center / cover;
        @media #{$sm} {
          bottom: 16px;
          left: -40px;
          width: 280px;
          height: 122px;
        }
        @media #{$md} {
          width: 320px;
          height: 140px;
          bottom: 8px;
          left: 4px;
        }
        @media #{$lg} {
          width: 484px;
          height: 211px;
          bottom: 1px;
          left: -16px;
        }
        @media #{$xl} {
          left: -50px;
          bottom: -1px;
        }
      }
    }
    .right {
      @media #{$sm} {
        flex-direction: row;
        flex: 0 0 100%;
      }
      @media #{$lg} {
        flex-direction: column;
        flex: 1 0 0;
      }
      .box {
        flex: 0 0 200px;
        justify-content: center;
        align-items: center;
        @media #{$sm} {
          flex: 0 0 calc((100% - 16px) / 2);
          margin: 0 16px 0 0;
          &:last-child {
            margin-right: 0;
          }
        }
        @media #{$lg} {
          flex: 0 0 250px;
          margin: 0 0 8px 0;
          &:last-child {
            margin-bottom: 0;
            flex: 1 0 0;
          }
        }
        @media #{$lg} {
          flex: 1 0 0;
          margin: 0 0 16px 0;
        }
      }
      .imagery {
        background: transparent url('/img/kanchanaburi-imagery-2.jpg') no-repeat center center /
          cover;
      }
      p {
        &.province {
          font-size: 48px;
          margin: 0 0 24px;
          line-height: 1;
          font-weight: bold;
          @media #{$sm} {
            font-size: 32px;
            margin: 0 0 12px;
          }
          @media #{$lg} {
            font-size: 40px;
            margin: 0 0 24px;
          }
          @media #{$xl} {
            font-size: 48px;
          }
        }
        &.meta {
          font-size: 20px;
          @media #{$sm} {
            font-size: 16px;
          }
          @media #{$lg} {
            font-size: 20px;
          }
          strong {
            display: block;
            line-height: 1.6;
            text-align: center;
          }
        }
      }
    }
  }
  .main-sponsor-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media #{$sm} {
      width: 100%;
      flex-direction: column;
    }
    @media #{$md} {
      flex-direction: row;
    }
    .logo {
      flex: 0 0 96px;
      min-width: 0;
      margin: 0 8px 0 0;
      @media #{$sm} {
        margin: 0 0 8px;
      }
      @media #{$xl} {
        margin: 0 8px 0 0;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
        @media #{$sm} {
          max-width: 100px;
        }
      }
    }
    .info {
      flex: 1 0 0;
      min-width: 0;
      h3 {
        font-size: 24px;
        margin: 0 0 12px;
        line-height: 1;
        @media #{$sm} {
          text-align: center;
          font-size: 20px;
        }
        @media #{$md} {
          text-align: left;
        }
        @media #{$xl} {
          font-size: 24px;
          margin: 0 0 16px;
        }
      }
      p {
        font-size: 20px;
        line-height: 1;
        margin: 0;
        @media #{$sm} {
          text-align: center;
          font-size: 16px;
        }
        @media #{$md} {
          font-size: 20px;
          text-align: left;
        }
      }
    }
  }
}

/**
* WHY
*/

.why-section-block {
  padding: 40px 0 0;
  margin: 0 0 40px;
  @media #{$lg} {
    margin: 0 0 80px;
  }
  .section-title.large {
    text-align: center;
  }
  .why-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media #{$lg} {
      flex-direction: row;
    }
    .imagery {
      flex: 0 0 240px;
      min-width: 0;
      margin: 0 0 16px;
      padding: 24px;
      height: auto;
      background: black url('/img/why-imagery-bg.jpg') no-repeat center center / cover;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      @media #{$lg} {
        flex: 0 0 350px;
        margin: 0 16px 0 0;
        padding: 40px;
        border-radius: 24px;
        height: 350px;
      }
      @media #{$xl} {
        height: 500px;
        flex: 0 0 500px;
      }
      img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 0 24px;
        @media #{$xl} {
          width: 80px;
          height: 80px;
        }
      }
      .section-title {
        color: $white;
        margin: 0 0 24px;
        line-height: 1;
        font-size: 24px;
        margin: 0 0 12px;
        @media #{$lg} {
          margin: 0 0 16px;
        }
      }
      p {
        color: $white;
        font-size: 16px;
        line-height: 1.6;
        margin: 0;
        @media #{$xl} {
          font-size: 20px;
        }
      }
    }
    .content {
      flex: 1 0 0;
      min-width: 0;
      .row-item {
        margin: 0 0 16px 0;
        padding: 24px;
        background-color: $egg;
        border-radius: 16px;
        @media #{$lg} {
          border-radius: 24px;
        }
        @media #{$xl} {
          padding: 40px 32px;
        }
        .row-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin: 0 0 16px;
          img {
            max-width: 32px;
            margin: 0 16px 0 0;
            position: relative;
            top: -3px;
            @media #{$lg} {
              max-width: 48px;
              top: -6px;
            }
          }
          h3 {
            font-size: 24px;
            margin: 0;
            @media #{$xl} {
              font-size: 28px;
            }
          }
        }
        p {
          font-size: 16px;
          line-height: 1.6;
          margin: 0;
          @media #{$xl} {
            font-size: 20px;
          }
        }
        &:last-child {
          margin-bottom: 0;
          background-color: $soft-blue;
        }
      }
    }
  }
}

/**
* GALLERY
*/

.gallery-block {
  margin: 0 0 64px;
  padding: 0 16px;
  @media #{$xl} {
    padding: 0;
    margin: 0 0 120px;
  }
  .slide-item {
    img {
      max-width: 100%;
      height: auto;
      display: block;
      border-radius: 8px;
    }
  }
}

/**
* QUALIFICATION
*/

.qualification-block {
  margin: 0 0 80px;
  ul {
    margin: 0 0 24px;
    li {
      font-family: 'yscweb';
      margin: 0 0 12px;
      line-height: 1.6;
      font-size: 16px;
      margin: 0 0 8px;
      @media #{$lg} {
        font-size: 18px;
      }
      @media #{$xl} {
        font-size: 20px;
      }
    }
  }
  .quote {
    text-align: center;
    font-size: 28px;
    margin: 40px auto;
    max-width: 768px;
    font-weight: normal;
    color: darken($grey, 20%);
    padding: 0 16px;
    @media #{$lg} {
      font-size: 40px;
      padding: 0;
    }
    @media #{$xl} {
      margin: 80px auto;
    }
  }
}

/**
* REWARD
*/

.reward-block {
  padding: 40px 0;
  background: $soft-blue;
  margin: 0 0 40px;
  @media #{$xl} {
    padding: 64px 0;
  }
  .prizes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 24px;
    @media #{$xl} {
      flex-direction: row;
      margin: 0 0 64px;
      align-items: center;
      justify-content: center;
    }
    .total {
      width: 100%;
      margin: 0 0 16px;
      background-size: 70%;
      text-align: center;
      @media #{$xl} {
        padding: 32px 24px;
        border-radius: 24px;
        border-radius: 16px;
        height: 280px;
        margin: 0 16px 0 0;
        flex: 0 0 380px;
        background: $white url('/img/prize-bg.png') no-repeat bottom right / 70%;
      }
      span {
        display: block;
        font-size: 0.8em;
        font-weight: normal;
        line-height: 1.8;
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-direction: flex-start;
      flex: 1 0 0;
      @media #{$md} {
        flex-direction: row;
        justify-content: center;
      }
      .item {
        margin: 0 0 40px;
        @media #{$xl} {
          flex: 0 0 calc((100% - 32px) / 3);
          margin: 0;
        }
        img {
          width: 80px;
          height: 80px;
          display: block;
          margin: 0 auto 32px;
        }
        p {
          text-align: center;
          line-height: 1.7;
          font-size: 16px;
          margin: 0 auto;
          max-width: 80%;
          @media #{$lg} {
          }
        }
      }
    }
  }
  .scholarship {
    margin: 0 0 48px;
    @media #{$lg} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .logo {
      max-width: 240px;
      margin: 0 auto 32px;
      border-radius: 4px;
      @media #{$lg} {
        flex: 0 0 200px;
        min-width: 0;
        max-width: 100%;
        margin: 0 32px 0 0;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
        border-radius: 4px;
      }
    }
    .content {
      h3 {
        text-align: center;
        max-width: 80%;
        margin: 0 auto 16px;
        line-height: 1.7;
        @media #{$md} {
          max-width: 100%;
        }
        @media #{$lg} {
          text-align: left;
        }
      }
      p {
        text-align: center;
        line-height: 1.7;
        margin: 0;
        @media #{$lg} {
          text-align: left;
        }
        @media #{$xl} {
          font-size: 20px;
        }
      }
    }
  }

  .certificates {
    padding-top: 120px;
    position: relative;
    @media #{$xl} {
      padding-top: 0;
    }
    h4 {
      text-align: center;
      @media #{$xl} {
        text-align: left;
      }
    }
    p {
      font-size: 16px;
      text-align: center;
      line-height: 1.6;
      margin: 0;
      @media #{$md} {
        margin: 0 auto;
        max-width: 500px;
      }
      @media #{$xl} {
        text-align: left;
        max-width: 800px;
        font-size: 20px;
        margin: 0;
      }
    }
    .otop-logo {
      width: 120px;
      height: 120px;
      content: '';
      display: block;
      position: absolute;
      top: -24px;
      transform: translateX(-50%);
      margin-left: 50%;
      right: 0;
      left: 0;
      background: url('/img/otop-logo.png') no-repeat center center / contain;
      @media #{$xl} {
        bottom: -24px;
        right: 0;
        transform: none;
        margin-left: 0;
        right: 0;
        left: auto;
      }
    }
  }
}

/**
* TIMELINE
*/

.timeline-block {
  padding-top: 40px;
  margin: 0 0 40px;
  text-align: center;
  @media #{$xl} {
    margin: 0 0 80px;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 32px auto;
    @media #{$xl} {
      margin: 64px auto;
    }
  }
  .timeline-mobile {
    display: block;
    @media #{$md} {
      display: none;
    }
  }
  .timeline-large {
    display: none;
    @media #{$md} {
      display: block;
    }
  }
}

/**
* APPLY
*/

.apply-block {
  margin: 0 0 40px;
  @media #{$md} {
    margin: 0 0 80px;
  }
  .apply-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media #{$md} {
      flex-direction: row;
    }
    .problems,
    .join {
      border: 3px solid $black;
      border-radius: 24px;
      padding: 40px 24px;
      @media #{$md} {
        flex: 0 0 calc((100% - 16px) / 2);
        align-self: stretch;
        min-width: 0;
      }
      @media #{$xl} {
        padding: 40px;
        flex: 0 0 calc((100% - 40px) / 2);
      }
      h3 {
        text-align: center;
      }
      p,
      ul li,
      ol li {
        font-size: 16px;
        line-height: 1.6;
        @media #{$lg} {
          font-size: 20px;
        }
        @media #{$xl} {
          font-size: 24px;
        }
      }
      ul {
        text-align: left;
        padding-left: 24px;
        @media #{$xl} {
          padding-left: 64px;
        }
      }
    }
    .problems {
      background: #000 url('/img/problem-bg.jpg') no-repeat center center / cover;
      text-align: center;
      position: relative;
      margin: 0 0 24px;
      @media #{$md} {
        margin: 0 16px 0 0;
      }
      @media #{$xl} {
        margin: 0 40px 0 0;
        min-height: 500px;
      }
      &:after {
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        position: absolute;
        content: '';
        display: block;
        background-color: rgba($black, 0.7);
        top: 8px;
        left: 8px;
        border-radius: 24px;
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
      }
      .content {
        position: relative;
        z-index: 2;
        h3 {
          color: $white;
        }
        img {
          width: 64px;
          height: 64px;
          display: block;
          margin: 0 auto 24px;
          @media #{$md} {
            width: 80px;
            height: 80px;
          }
        }
        p {
          color: $white;
          margin: 0 0 24px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .join {
      text-align: center;
      strong {
        color: red;
        text-decoration: underline;
        font-weight: normal;
      }
      span {
        font-size: 0.7em;
      }
      .button {
        margin: 16px 0;
        padding: 8px 32px 6px;
        height: 64px;
        font-size: 24px;
        color: $white;
        @media #{$xl} {
          margin: 24px 0 16px;
        }
      }
    }
  }
}

/**
* CRIETERIA
*/

.criteria-block {
  margin: 0 0 40px;
  @media #{$lg} {
    margin: 0 0 80px;
  }
  h3 {
    margin: 0 0 16px;
    text-align: center;
  }
  p {
    text-align: center;
    font-size: 20px;
    margin: 0 auto 40px;
    max-width: 80%;
    @media #{$md} {
      margin: 0 0 64px;
      font-size: 24px;
      max-width: 100%;
    }
  }
  .list {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media #{$md} {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
    @media #{$lg} {
      max-width: 1080px;
    }
    .item {
      margin: 0 0 40px;
      @media #{$md} {
        flex: 0 0 calc((100% - 32px) / 3);
        margin-right: 16px;
      }
      @media #{$lg} {
        flex: 0 0 calc((100% - 48px) / 3);
        margin-right: 24px;
      }
      &:last-child {
        margin-right: 0;
      }
      p.percent {
        max-width: 100%;
        font-size: 64px;
        font-weight: bold;
        line-height: 1;
        margin: 0 0 16px;
        display: block;
        @media #{$md} {
          font-size: 48px;
        }
        @media #{$xl} {
          font-size: 64px;
        }
      }
      p.text {
        max-width: 100%;
        display: block;
        margin: 0 auto;
        @media #{$md} {
          font-size: 18px;
        }
        @media #{$lg} {
          font-size: 20px;
        }
        @media #{$xl} {
          font-size: 24px;
        }
      }
    }
  }
}

/**
* SPONSORS
*/

.backed-by-block {
  margin: 0 0 40px;
  @media #{$xl} {
    margin: 0 0 80px;
  }
  h3 {
    margin: 0 0 16px;
    text-align: center;
    @media #{$md} {
      margin: 0 0 24px;
    }
    @media #{$xl} {
      text-align: left;
    }
  }
  .backed-by-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media #{$lg} {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  .main {
    margin: 0 0 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media #{$md} {
      margin: 0 0 16px;
    }
    @media #{$lg} {
      flex: 0 0 300px;
      min-width: 0;
      margin: 0 24px 0 0;
      justify-content: flex-start;
    }
    .logo {
      flex: 0 0 120px;
      min-width: 0;
      border-radius: 8px;
      margin: 0 0px 0 0;
      @media #{$lg} {
        margin-right: 16px;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
    .content {
      min-width: 0;
      margin: 0;
      @media #{$lg} {
        flex: 1 0 0;
      }
      h4 {
        font-size: 24px;
        margin: 0 0 16px;
        line-height: 1;
      }
      p {
        font-size: 24px;
        margin: 0;
        line-height: 1;
      }
    }
  }
  .other {
    flex: 1 0 0;
    @media #{$lg} {
      padding-left: 24px;
      border-left: 1px solid rgba($black, 0.1);
    }
    .list {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      @media #{$lg} {
        text-align: left;
      }
      li {
        display: inline-block;
        margin: 0 16px 8px 0;
        vertical-align: middle;
        &:last-child {
          margin-right: 0;
        }
        img {
          max-height: 48px;
          max-width: 100%;
          display: block;
          margin: 0 auto 8px;
          @media #{$xl} {
            max-height: 72px;
            margin: 0 auto;
          }
          &.horizontal {
            max-height: 32px;
            @media #{$xl} {
              max-height: 48px;
            }
          }
        }
      }
    }
  }
}

.footer {
  padding: 24px 0;
  @media #{$xl} {
    padding: 24px 0 40px;
  }
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media #{$md} {
      flex-direction: row;
      justify-content: space-between;
    }
    .left,
    .right {
      flex: 1 0 0;
      min-width: 0;
    }
    .right {
      p {
        @media #{$md} {
          text-align: right;
        }
      }
    }
    p {
      text-align: center;
      @media #{$md} {
        text-align: left;
      }
    }
  }
  .footer-logo {
    max-width: 100%;
    height: 56px;
    display: block;
    margin: 0 auto 16px;
    @media #{$md} {
      height: 80px;
      margin: 0 0 16px;
    }
  }
  .social-list {
    margin: 16px 0 0;
    padding: 0;
    list-style: none;
    text-align: center;
    @media #{$md} {
      text-align: right;
    }
    li {
      display: inline-block;
      margin: 0 8px 0 0;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 32px;
        height: 32px;
        display: block;
      }
    }
  }
}

.winner-section {
  margin: 0 0 40px;
  @media #{$lg} {
    margin: 0 0 80px;
  }
  .item {
    margin: 0 0 32px;
    img {
      max-width: 100%;
      height: auto;
      display: block;
      border-radius: 8px;
      margin: 0 0 24px;
    }
  }
}
